import React, { useRef, ReactNode, ComponentType } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  useMediaQuery,
  Link,
  FilledInput,
  IconButton,
} from "@mui/material";
import { PaperProps } from "@mui/material/Paper";

import { useTranslation } from "react-i18next";

import SearchIcon from "@/common/icons/SearchIcon";

import useStyles from "./UserListDialog.styles";

export type UserListDialogProps = {
  open: boolean;
  title: string;
  subtitle?: ReactNode;
  headerLeftAction?: ReactNode;
  headerRightAction?: ReactNode;
  PeperComponent?: ComponentType<PaperProps>;
  onSearch?: (data: string) => void;
  onClose: () => void;
  children: React.ReactNode;
  isScrolled?: boolean;
};

export const UserListDialog = ({
  open,
  title,
  subtitle,
  headerLeftAction,
  headerRightAction,
  PeperComponent,
  onSearch,
  onClose,
  children,
  isScrolled,
}: UserListDialogProps) => {
  const { classes, cx } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const searchField = useRef<HTMLInputElement>();

  const handleSearch = (value: string) => onSearch && onSearch(value);

  return (
    <Dialog
      fullScreen={isMobile}
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={onClose}
      PaperComponent={PeperComponent}>
      <DialogTitle classes={{ root: cx(classes.headerRoot, { [classes.withShadow]: isScrolled }) }}>
        <div className={classes.header}>
          {headerLeftAction || (
            <Link component="button" type="button" underline="none" onClick={onClose}>
              <span className={classes.action}>{t("Generic.Cancel")}</span>
            </Link>
          )}
          <span className={classes.headerTitle}>{title}</span>
          {headerRightAction || (
            <IconButton classes={{ root: classes.closeButton }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        {onSearch && (
          <div className={classes.searchContainer}>
            <SearchIcon className={classes.searchIcon} />
            <FilledInput
              inputRef={searchField}
              margin="dense"
              placeholder={t("Chat.SearchChatHint")}
              fullWidth
              disableUnderline
              className={classes.searchInput}
              onChange={(event) => handleSearch(event.target.value)}
            />
          </div>
        )}
        {subtitle}
      </DialogTitle>

      <DialogContent classes={{ root: classes.content }} id="scrollableDiv">
        {children}
      </DialogContent>
    </Dialog>
  );
};
